<template>
  <v-row>
    <v-col xs="12">
      <v-card class="mx-auto">
        <v-list-item class="primary">
          <v-list-item-content>
            <v-list-item-title
              v-if="isNew"
              class="headline text-left white--text"
              >Add Sub-Entity</v-list-item-title
            >
            <v-list-item-title v-else class="headline text-left white--text"
              >Edit Sub-Entity</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-card-text>
          <v-row>
            <v-col class="py-0" sm="12">
              <v-text-field
                v-model="auditSubEntity.Description"
                label="Description"
                :rules="[(v) => !!v || 'required']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0" sm="12">
              <v-text-field
                v-model="auditSubEntity.Address.Address1"
                label="Mailing Address"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0" sm="12" md="4">
              <v-text-field
                v-model="auditSubEntity.Address.City"
                label="City"
              ></v-text-field>
            </v-col>
            <v-col class="py-0" sm="12" md="4">
              <v-select
                v-model="auditSubEntity.Address.StateId"
                :items="luState"
                item-text="Abbreviation"
                item-value="ID"
                label="State"
                menu-props="auto, overflowY"
                clearable
              >
              </v-select>
            </v-col>
            <v-col class="py-0" sm="12" md="4">
              <v-text-field
                v-model="auditSubEntity.Address.Zip"
                label="Zip"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0" sm="12">
              <v-text-field
                v-model="auditSubEntity.PAddress.Address1"
                label="Physical Address"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0" sm="12" md="4">
              <v-text-field
                v-model="auditSubEntity.PAddress.City"
                label="City"
              ></v-text-field>
            </v-col>
            <v-col class="py-0" sm="12" md="4">
              <v-select
                v-model="auditSubEntity.PAddress.StateId"
                :items="luState"
                item-text="Abbreviation"
                item-value="ID"
                label="State"
                menu-props="auto, overflowY"
                clearable
              >
              </v-select>
            </v-col>
            <v-col class="py-0" sm="12" md="4">
              <v-text-field
                v-model="auditSubEntity.PAddress.Zip"
                label="Zip"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0" sm="12" md="2">
              <v-checkbox
                v-model="auditSubEntity.IsActive"
                label="Is Active?"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<!-- SCRIPTS -->
<script>
import {mask} from 'vue-the-mask'
import {get, sync, commit, call} from 'vuex-pathify'
import Rules from '@validation/rules'

export default {
  directives: {
    mask,
  },
  model: {
    prop: 'auditSubEntity',
    event: 'change',
  },
  props: {
    auditSubEntity: Object,
  },
  data: () => ({
    rules: Rules,
    maskZip: '#####',
    valid: true,
    lazy: false,
    State: ['Cheyenne', 'Casper', 'Alabama'],
  }),
  created() {
    this.loadLuState()
  },

  computed: {
    luState: get('luState/luState'),

    isNew() {
      return !this.$route.params.subEntityId > 0
      // return true
    },
  },
  methods: {
    loadLuState: call('luState/loadLuState'),
  },
}
</script>

<!-- STYLES -->
<style lang="scss">
//@import '@design';
</style>
