<template>
  <v-container fluid>
    <div>
      <v-breadcrumbs :items="breadcrumbs" divider=">" large></v-breadcrumbs>
    </div>
    <v-row>
      <v-col xs="12">
        <v-form ref="form" v-model="valid" lazy-validation>
          <subEntityDetails
            :auditSubEntity="selectedAuditSubEntity"
          ></subEntityDetails>
        </v-form>
        <!-- SAVE BTN -->
        <v-row v-if="isSuperUser || isProjectCreator" justify="end">
          <v-col sm="auto">
            <v-btn @click="validateAndSave" :disabled="saving" color="primary">
              <v-icon left>mdi-content-save</v-icon>Save Sub-Entity
            </v-btn>
          </v-col>
          <!-- COPY BTN -->
          <v-col sm="auto">
            <v-btn @click="copyEntityDetails" color="primary">
              <v-icon left>mdi-content-copy</v-icon>Copy Entity Details
            </v-btn>
          </v-col>
        </v-row>
        <!-- ENTITY CONTACTS -->
        <entityContacts
          v-if="!isNew"
          :subEntityId="selectedAuditSubEntity.ID"
          :entityContacts="selectedAuditSubEntity.SubEntityContact"
          @refresh="refresh"
        ></entityContacts>
        <!-- PROJECTS -->
        <projects
          v-if="!isNew"
          :subEntityId="selectedAuditSubEntity.ID"
        ></projects>
      </v-col>
    </v-row>
  </v-container>
</template>

<!-- SCRIPTS -->
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import {authMethods, authComputed} from '@state/helpers'
import AuditSubEntity from '@classes/AuditSubEntity'
import EntityAddress from '@classes/EntityAddress'
import subEntityDetails from '@components/subEntity/subEntityDetails'

import entityContacts from '@components/entity/entityContacts'
import projects from '@components/projects/projects'
export default {
  components: {
    subEntityDetails,
    entityContacts,
    projects,
  },
  page() {
    return {
      title: this.selectedAuditSubEntity
        ? this.selectedAuditSubEntity.Description
        : 'Sub-Entity',
    }
  },
  data: () => ({
    valid: true,
    selectedAuditSubEntity: new AuditSubEntity(),
    saving: false,
  }),
  computed: {
    ...authComputed,
    selectedAuditEntity: get('auditEntity/selectedAuditEntity'),
    isNew() {
      return !this.selectedAuditSubEntity.ID > 0
    },
    entity() {
      if (this.$route.params.subEntityId) {
        return this.selectedAuditSubEntity.Entity || {}
      } else {
        return this.selectedAuditEntity || {}
      }
    },
    breadcrumbs() {
      return [
        {
          text: 'Search',
          disabled: false,
          to: '/entities',
        },
        {
          text: `Entity: ${this.entity.FullName}`,
          disabled: false,
          exact: true,
          to: `/entity/${this.entity.ID}`,
        },
      ]
    },
  },
  methods: {
    saveAuditSubEntity: call('auditSubEntity/saveAuditSubEntity'),
    ...call('auditEntity', ['loadSelectedAuditEntity']),
    validateAndSave() {
      this.validate().then(() => {
        if (this.valid) {
          let subEntity = this.selectedAuditSubEntity.getSaveData()
          if (this.currentUser.DivisionID != 4)
            subEntity.AuditSubEntity_PF = null
          this.saving = true
          this.saveAuditSubEntity(subEntity).then(
            (res) => {
              this.saving = false
              if (this.selectedAuditSubEntity.ID == 0 && res.data) {
                this.$router.push({
                  name: 'subEntity',
                  params: {
                    subEntityId: res.data.ID,
                    entityId: res.data.EntityID,
                  },
                })
              }
            },
            (err) => {
              this.saving = false
            }
          )
        }
      })
    },
    copyEntityDetails() {
      this.selectedAuditSubEntity.EntityID = this.selectedAuditEntity.ID
      this.selectedAuditSubEntity.Description = this.selectedAuditEntity.FullName
      this.selectedAuditSubEntity.AddressID = this.selectedAuditEntity.AddressId
      this.selectedAuditSubEntity.Address = new EntityAddress(
        this.selectedAuditEntity.Address
      )
      this.selectedAuditSubEntity.PAddressID = this.selectedAuditEntity.PAddressID
      this.selectedAuditSubEntity.PAddress = new EntityAddress(
        this.selectedAuditEntity.PAddress
      )
    },
    loadSelectedAuditSubEntity: call(
      'auditSubEntity/loadSelectedAuditSubEntity'
    ),
    refresh() {
      this.loadSelectedAuditSubEntity(this.$route.params.subEntityId).then(
        (res) => {
          this.selectedAuditSubEntity = new AuditSubEntity(res.data)
        }
      )
    },
    async validate() {
      this.$refs.form.validate()
    },
  },
  created() {
    if (this.$route.params.subEntityId) {
      this.refresh()
    } else {
      this.selectedAuditSubEntity = new AuditSubEntity({
        EntityID: parseInt(this.$route.params.entityId),
      })
    }
    this.loadSelectedAuditEntity(this.$route.params.entityId)
  },
}
</script>

<!-- STYLES -->
<style lang="scss">
//@import '@design';
</style>
