<template>
  <v-row>
    <v-col xs="12">
      <v-card class="mx-auto>">
        <v-list-item class="primary">
          <v-list-item-content>
            <v-list-item-title class="headline text-left white--text"
              >Projects</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-card-text>
          <v-row justify="end">
            <v-col sm="auto">
              <v-text-field
                v-model="searchText"
                prepend-inner-icon="mdi-text-box-search"
                label="Search"
                clearable
              ></v-text-field>
            </v-col>
            <v-col sm="auto" class="mt-2">
              <v-checkbox
                v-model="allProjects"
                label="All Projects?"
              ></v-checkbox>
            </v-col>
            <v-col
              v-if="isSuperUser || isProjectCreator"
              sm="auto"
              class="mt-4"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-btn
                    color="primary"
                    v-on="on"
                    fab
                    depressed
                    small
                    @click="addProject()"
                  >
                    <v-icon dark>mdi-text-box-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add New Project</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
        <template>
          <v-data-table
            :headers="filteredHeaders"
            :items="projects"
            :server-items-length="total"
            :loading="loading"
            :sort-by.sync="pagination.sortBy"
            :sort-desc.sync="pagination.descending"
            :page.sync="pagination.page"
            :items-per-page.sync="pagination.rowsPerPage"
            :footer-props="{
              itemsPerPageOptions: [5, 10, 25, 50],
            }"
            class="pt-4"
            dense
          >
            <template v-slot:item.project="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-btn
                    small
                    color="primary"
                    v-on="on"
                    icon
                    @click="editProject(item.ID)"
                  >
                    <v-icon class="clickable" color="primary"
                      >mdi-pencil</v-icon
                    >
                  </v-btn>
                </template>
                <span>Edit Project</span>
              </v-tooltip>
            </template>
            <template v-slot:item.AuditStart="{item}">
              {{ item.AuditStart | dateformat }}
            </template>
            <template v-slot:item.AuditEnd="{item}">
              {{ item.AuditEnd | dateformat }}
            </template>
            <template v-slot:item.IsActive="{item}">{{
              item.IsActive ? 'Active' : 'Inactive'
            }}</template>
          </v-data-table>
        </template>
      </v-card>
    </v-col>
  </v-row>
</template>

<!-- SCRIPTS -->
<script>
import {debounce, range} from 'lodash'
import {authMethods, authComputed} from '@state/helpers'
export default {
  props: {
    subEntityId: Number,
  },
  data: () => ({
    searchText: '',
    projects: [],
    total: 0,
    searchCount: 0,
    pagination: {
      page: 1,
      sortBy: 'Description',
      descending: false,
      rowsPerPage: 25,
    },
    search: '',
    allProjects: false,
  }),
  created() {
    this.searchProjects()
  },
  watch: {
    currentUser(nval, oval) {
      if (!!nval) {
        this.bounce(this)
      }
    },
    allProjects: {
      handler() {
        this.bounce(this)
      },
    },
    searchText: {
      handler() {
        this.bounce(this)
      },
    },
    pagination: {
      handler() {
        this.bounce(this)
      },
      deep: true,
    },
  },
  computed: {
    ...authComputed,
    loading() {
      return this.searchCount > 0
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
    headers() {
      return [
        {
          text: 'Project',
          align: 'center',
          value: 'project',
          sortable: false,
          visible: true,
        },
        {
          text: 'Project Number',
          align: 'center',
          value: 'ProjectNumber',
          visible: true,
        },
        {
          text: 'Description',
          align: 'center',
          value: 'Description',
          visible: true,
        },
        {
          text: 'Start',
          align: 'center',
          value: 'AuditStart',
          visible: true,
        },
        {
          text: 'End',
          align: 'center',
          value: 'AuditEnd',
          visible: true,
        },
        {
          text: 'Active?',
          align: 'center',
          value: 'IsActive',
          visible: this.allProjects,
        },
      ]
    },
  },
  methods: {
    searchProjects() {
      if (this.currentUser) {
        if (!this.pagination.sortBy) {
          this.pagination.sortBy = 'Description'
          this.pagination.descending = false
        }
        if (!this.searchText) {
          this.searchText = ''
        }
        this.searchCount++
        const url = `ProjectSearch?pageNum=${
          this.pagination.page - 1
        }&pageSize=${this.pagination.rowsPerPage}&sortProperty=${
          this.pagination.sortBy
        }&sortDirection=${
          this.pagination.descending ? 'desc' : 'asc'
        }&searchText=${this.searchText}&allProjects=${
          this.allProjects
        }&subEntityId=${this.subEntityId}
        &divisionId=${this.currentUser.DivisionID}`
        this.$axios.get(url).then(
          (res) => {
            this.pagination.rowsPerPage = res.data.Page.Size
            this.pagination.page = res.data.Page.Number + 1
            this.total = res.data.Page.TotalElements
            this.projects = res.data.Entries
            this.searchCount--
          },
          (error) => {
            this.error = error
            this.handleError(error)
            console.error(error)
            this.searchCount--
          }
        )
      }
    },
    addProject() {
      this.$router.push({
        name: 'project',
        params: {subEntityId: this.subEntityId},
      })
    },
    editProject(id) {
      this.$router.push({
        name: 'project',
        params: {subEntityId: this.subEntityId, projectId: id},
      })
    },
    bounce: _.debounce((self) => {
      self.searchProjects()
    }, 750),
  },
}
</script>

<!-- STYLES -->
<style lang="scss">
//@import '@design';
</style>