import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c('div',[_c(VBreadcrumbs,{attrs:{"items":_vm.breadcrumbs,"divider":">","large":""}})],1),_c(VRow,[_c(VCol,{attrs:{"xs":"12"}},[_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('subEntityDetails',{attrs:{"auditSubEntity":_vm.selectedAuditSubEntity}})],1),(_vm.isSuperUser || _vm.isProjectCreator)?_c(VRow,{attrs:{"justify":"end"}},[_c(VCol,{attrs:{"sm":"auto"}},[_c(VBtn,{attrs:{"disabled":_vm.saving,"color":"primary"},on:{"click":_vm.validateAndSave}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v("Save Sub-Entity ")],1)],1),_c(VCol,{attrs:{"sm":"auto"}},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.copyEntityDetails}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-content-copy")]),_vm._v("Copy Entity Details ")],1)],1)],1):_vm._e(),(!_vm.isNew)?_c('entityContacts',{attrs:{"subEntityId":_vm.selectedAuditSubEntity.ID,"entityContacts":_vm.selectedAuditSubEntity.SubEntityContact},on:{"refresh":_vm.refresh}}):_vm._e(),(!_vm.isNew)?_c('projects',{attrs:{"subEntityId":_vm.selectedAuditSubEntity.ID}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }