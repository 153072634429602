import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{attrs:{"xs":"12"}},[_c(VCard,{staticClass:"mx-auto>"},[_c(VListItem,{staticClass:"primary"},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"headline text-left white--text"},[_vm._v("Projects")])],1)],1),_c(VCardText,[_c(VRow,{attrs:{"justify":"end"}},[_c(VCol,{attrs:{"sm":"auto"}},[_c(VTextField,{attrs:{"prepend-inner-icon":"mdi-text-box-search","label":"Search","clearable":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c(VCol,{staticClass:"mt-2",attrs:{"sm":"auto"}},[_c(VCheckbox,{attrs:{"label":"All Projects?"},model:{value:(_vm.allProjects),callback:function ($$v) {_vm.allProjects=$$v},expression:"allProjects"}})],1),(_vm.isSuperUser || _vm.isProjectCreator)?_c(VCol,{staticClass:"mt-4",attrs:{"sm":"auto"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"color":"primary","fab":"","depressed":"","small":""},on:{"click":function($event){return _vm.addProject()}}},on),[_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-text-box-plus")])],1)]}}],null,false,47117667)},[_c('span',[_vm._v("Add New Project")])])],1):_vm._e()],1)],1),[_c(VDataTable,{staticClass:"pt-4",attrs:{"headers":_vm.filteredHeaders,"items":_vm.projects,"server-items-length":_vm.total,"loading":_vm.loading,"sort-by":_vm.pagination.sortBy,"sort-desc":_vm.pagination.descending,"page":_vm.pagination.page,"items-per-page":_vm.pagination.rowsPerPage,"footer-props":{
            itemsPerPageOptions: [5, 10, 25, 50],
          },"dense":""},on:{"update:sortBy":function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.pagination, "descending", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.pagination, "descending", $event)},"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "rowsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.pagination, "rowsPerPage", $event)}},scopedSlots:_vm._u([{key:"item.project",fn:function(ref){
          var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"small":"","color":"primary","icon":""},on:{"click":function($event){return _vm.editProject(item.ID)}}},on),[_c(VIcon,{staticClass:"clickable",attrs:{"color":"primary"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Project")])])]}},{key:"item.AuditStart",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateformat")(item.AuditStart))+" ")]}},{key:"item.AuditEnd",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateformat")(item.AuditEnd))+" ")]}},{key:"item.IsActive",fn:function(ref){
          var item = ref.item;
return [_vm._v(_vm._s(item.IsActive ? 'Active' : 'Inactive'))]}}])})]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }